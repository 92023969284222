// src/server.js
import { writeFileSync } from "fs";
import { Factory, Model, Request, Response, createServer } from "miragejs";
import { ModelDefinition, Registry } from "miragejs/-types";
import Schema from "miragejs/orm/schema";
import items from "./__mocks__/items.json";

type Item = {
  name: string;
};

const ItemModel: ModelDefinition<Item> = Model.extend({});

type AppRegistry = Registry<{ item: typeof ItemModel }, {}>;
type AppSchema = Schema<AppRegistry>;

export default function () {
  createServer({
    models: {
      item: Model,
    },

    factories: {
      item: Factory.extend({
        quantity: () => 1,
        price: () => 1,
        section: () => 1,
        created: () => new Date().toISOString(),
      }),
    },
    seeds(server) {
      console.log(localStorage.getItem("db"));
      if (localStorage.getItem("db") !== null) {
        server.db.loadData(JSON.parse(localStorage.getItem("db") as string));
      } else {
        console.log(items);
        server.db.loadData({ items });
      }
    },
    routes() {
      this.get("/items", (schema) => {
        return schema.db.items;
      });
      this.post("/item", (schema: AppSchema, request) => {
        let attrs = JSON.parse(request.requestBody);
        this.create("item", attrs);
        // schema.db.items.insert(item.attrs);
        localStorage.setItem("db", JSON.stringify(schema.db.dump()));
        console.log(schema.db.dump());
        return attrs;
      });
      this.put("/item/:id", (schema: AppSchema, request) => {
        schema.db.items.update(request.params.id, JSON.parse(request.requestBody));
        localStorage.setItem("db", JSON.stringify(schema.db.dump()));
        return new Response(201);
      });
      this.delete("/item/:id", (schema: AppSchema, request) => {
        return new Response(201);
      });
      this.post("/sso/login", (schema, request) => {
        return { user: "test@test.com" };
      });
    },
  });
}
