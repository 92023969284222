import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export function Login() {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/sso/login`, {
      method: "POST",
      body: JSON.stringify({
        token: searchParams.get("token"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }).then(
      (resp: Response) => {
        navigate("/");
        return;
      },
      (err: Error) => {
        console.log(err);
      }
    );
  }, []);
  return null;
}
