import React from "react";
// @ts-ignore
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "./Item.scss";
import Plus from "../static/plus.svg";
import Minus from "../static/minus.svg";
import Cancel from "../static/cancel.svg";
import Five from "../static/5.svg";
import One from "../static/1.svg";
import Undo from "../static/undo.svg";
import Trash from "../static/trash.svg";

import Done from "../static/done.svg";

export function SortableItem(props: {
  id: any;
  item: any;
  updatePrice: CallableFunction;
  updateQuantity: CallableFunction;
  setConfirmed: CallableFunction;
  updateSection: CallableFunction;
  delete: CallableFunction;
  sections: string[];
}) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr className={props.item.done === true ? "item--done" : ""}>
      <td>
        {props.item.name} ({props.item.quantity})
      </td>
      <td>
        <select onChange={(e) => props.updateSection(props.item.id, e.target.value)} defaultValue={props.item.section}>
          {props.sections.map((item: string, idx: number) => (
            <option value={idx} key={item}>
              {item}
            </option>
          ))}
        </select>
      </td>
      <td>
        <img className="icon-md" src={Done} onClick={() => props.setConfirmed(props.item.id)} />
        <img className="icon-md item--reset" src={Undo} onClick={() => props.setConfirmed(props.item.id, false)} />
        <img
          className="icon-md"
          src={Minus}
          onClick={() => props.updateQuantity(props.item.id, props.item.quantity - 1)}
        />
        <img
          className="icon-md"
          src={Plus}
          onClick={() => props.updateQuantity(props.item.id, props.item.quantity + 1)}
        />
        <img
          className="icon-md"
          src={Cancel}
          onClick={() => {
            props.updateQuantity(props.item.id, 0);
          }}
        />
        <img
          className="icon-md"
          src={Five}
          onClick={() => props.updateQuantity(props.item.id, props.item.quantity + 5)}
        />
        <img className="icon-md" src={One} onClick={() => props.updateQuantity(props.item.id, 1)} />
        <img className="icon-md" src={Trash} onClick={() => props.delete(props.item.id)} />
      </td>
    </tr>
  );
}
