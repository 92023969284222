import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "@snapatoms/ui/dist/bundle.css";
import App, { endpoint } from "./App";
import reportWebVitals from "./reportWebVitals";

import {
  createBrowserRouter,
  Link,
  Navigate,
  Outlet,
  RouterProvider,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { Login } from "./pages/Login";
import mirage from "./server";
import { Test } from "./pages/Test";

process.env.MOCK && mirage();

function ProtectedRoute({ element }: any) {
  // Replace this with your authentication logic
  const isAuthenticated = false;
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    fetch(`${endpoint}/user/confirm`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          window.location.href = `https://auth.charleybyrne.com?redirect=${window.location}login`;
          return;
        }
      })
      .catch((err: Error) => {
        window.location.href = `https://auth.charleybyrne.com?redirect=${window.location}login`;
        return;
      });
  }, []);

  return element;
}

function Layout() {
  return (
    <main>
      <header>
        <div className="header-nav">
          <div className="header-item">
            <Link to="/test">Test</Link>
          </div>
        </div>
      </header>
      <Outlet />
    </main>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoute element={<Layout />}></ProtectedRoute>,
    children: [
      {
        index: true,
        element: <App />,
      },
      {
        path: "test",
        element: <Test />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
]);

function Logout() {
  const navigate = useNavigate();
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API!}/logout`, { credentials: "include" }).then((resp: Response) => {
      console.log(resp);
    });
    navigate("/");
    return;
  }, []);
  return <></>;
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
