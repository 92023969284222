import React, { useEffect, useState } from "react";
import "./App.scss";
// @ts-ignore
import { KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
// @ts-ignore
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { SortableItem } from "./components/Item";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import SimpleSnackbar from "./components/Snackbar";
import Plus from "./static/plus.svg";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const endpoint = process.env.REACT_APP_API || "";

function App() {
  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [value, setValue] = React.useState(0);
  const [total, setTotal] = useState(0.0);
  const [search, setSearchFilter] = useState("");
  const [newItem, setNewItem] = useState("");
  const [alerts, setAlerts] = useState([] as any);

  const [sections, setSections] = useState([] as any);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    fetch(`${endpoint}/items`, { credentials: "include" })
      .then(
        async (resp: Response) => {
          if (!resp.ok) {
            return;
          }
          const data: any = await resp.json();

          setAllItems(data);
          setItems(data);
          setSections([
            "All",
            "Fruit & Veg",
            "Nourish",
            "Meat",
            "Fish",
            "Cheese & Dairy",
            "Cleaning",
            "Frozen",
            "Household",
          ]);
        },
        (err: Error) => {
          console.log("error", err);
        }
      )
      .catch((err: Error) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let _local = 0;
    allItems.forEach((item: any) => {
      _local += item.price * item.quantity;
    });

    setTotal(_local);
  }, [allItems]);

  useEffect(() => {
    const _ = [...allItems]
      .filter((item: any) => {
        if (value === 0) {
          return item;
        } else if (parseInt(value.toString()) === parseInt(item.section)) {
          return item;
        }
      })
      .filter((item: any) => {
        if (search !== "" && search !== undefined) {
          if (RegExp(`${search.toLowerCase()}.*`, "i").test(item.name.toLowerCase())) {
            return item;
          }
        } else {
          return item;
        }
      });

    setItems(_);
  }, [value, search, allItems]);

  async function updatePrice(id: string, price: number) {
    let update = {};
    const _: any = allItems.map((item: any) => {
      if (item.id === id) {
        item.price = price;
      }
      return item;
    });
    setAllItems(_);
    await fetch(`${endpoint}/items/${id}?price=${price}`, {
      method: "PUT",
      body: JSON.stringify(update),
      credentials: "include",
    });
  }

  async function updateQuantity(id: string, quantity: number) {
    let update = {};
    const _: any = allItems.map((item: any) => {
      if (item.id === id) {
        item.quantity = quantity;
        update = item;
      }
      return item;
    });
    setAllItems(_);
    await fetch(`${endpoint}/item/${id}?quantity=${quantity}`, {
      method: "PUT",
      body: JSON.stringify(update),
      credentials: "include",
    });
  }

  async function setConfirmed(id: string, flag: boolean = true) {
    const _ = [...allItems];
    let update = {};
    _.map((item: any) => {
      if (item.id === id) {
        item.done = flag;
        update = item;
      }
      return item;
    });
    await fetch(`${endpoint}/item/${id}?done=true`, {
      method: "PUT",
      body: JSON.stringify(update),
      credentials: "include",
    });
    setAllItems(_);
  }

  async function updateSection(id: number, value: number) {
    const _ = [...allItems];
    let update = {};
    _.map((item: any) => {
      if (item.id === id) {
        item.section = value;
        update = item;
      }
      return item;
    });
    setAllItems(_);
    await fetch(`${endpoint}/item/${id}?section=${value}`, {
      method: "PUT",
      body: JSON.stringify(update),
      credentials: "include",
    });
  }

  function deleteItem(id: number) {
    fetch(`${endpoint}/item/${id}`, { method: "DELETE" }).then((resp: Response) => {
      const _ = [...allItems];
      setAllItems(_.filter((item: any) => item.id !== id));
    });
  }

  function handleDragEnd(event: any) {
    const { active, over }: { active: any; over: any } = event;

    if (active && over && active.id !== over.id) {
      setItems((items) => {
        // @ts-ignore
        const oldIndex = items.findIndex((i) => i.id === active.id);
        // @ts-ignore
        const newIndex = items.findIndex((i) => i.id === over.id);
        // return arrayMove(items, oldIndex, newIndex);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const addNewItem = () => {
    fetch(`${endpoint}/item`, {
      method: "POST",
      body: JSON.stringify({ name: newItem, section: value }),
    }).then((resp: any) => {
      if (newItem === "") {
        setAlerts(["Cannot add an empty item"]);
        setTimeout(() => {
          setAlerts([]);
        }, 1000);
        return;
      }
      setAllItems(
        allItems.concat({
          id: allItems.length + 2,
          name: newItem,
          section: value,
          price: 1,
          quantity: 0,
          order: 99,
        } as any)
      );
    });
  };

  return (
    <div className="App">
      {alerts.map((alert: any, idx: number) => {
        return <SimpleSnackbar key={new Date().getMilliseconds() + idx} message={alert}></SimpleSnackbar>;
      })}
      <header className="App-header">
        <div className="row align-center">
          <div className="col">
            <p>Total estimated shop: {total.toFixed(2)}</p>
            <p>
              Total confirmed shop:{" "}
              {(allItems || [])
                .filter((i: any) => i.done)
                .reduce((acc: any, next: any) => acc + next.quantity * next.price, 0)}
            </p>
          </div>
          <div className="col">
            <div className="row align-center">
              <div className="col">
                <img className="icon-md" src={Plus} onClick={() => addNewItem()} />
              </div>
              <div className="col">
                <input
                  type="text"
                  placeholder="Enter food item to add ..."
                  onChange={(e) => setNewItem(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <Box sx={{ borderBottom: 1, borderColor: "divider", scrollBehavior: "auto", width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            allowScrollButtonsMobile={true}
            textColor="inherit">
            <Tab label="All" {...a11yProps(0)} />
            <Tab label="Fruit & Veg" {...a11yProps(1)} />
            <Tab label="Nourish" {...a11yProps(2)} />
            <Tab label="Meat" {...a11yProps(3)} />
            <Tab label="Fish" {...a11yProps(4)} />
            <Tab label="Cheese & Dairy" {...a11yProps(5)} />
            <Tab label="Cleaning" {...a11yProps(6)} />
            <Tab label="Frozen" {...a11yProps(7)} />
            <Tab label="Household" {...a11yProps(8)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          Item One
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          Item Two
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          Item Three
        </CustomTabPanel>

        <div className="container">
          <div className="row flex-wrap">
            <div className="col">
              <input type="text" onChange={(e) => setSearchFilter(e.target.value)} placeholder="Search for item" />
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Move Section</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {[...items]
                .sort((a: any, b: any) => (a.done !== b.done ? 1 : -1))
                .map((item: any) => {
                  return (
                    <SortableItem
                      key={item.id}
                      id={item.id}
                      item={item}
                      updatePrice={updatePrice}
                      updateQuantity={updateQuantity}
                      setConfirmed={setConfirmed}
                      updateSection={updateSection}
                      delete={deleteItem}
                      sections={sections}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
      </header>
    </div>
  );
}

export default App;
